<template>
  <div>
    <b-button
      variant="outline-info"
      block
      @click="refModal.show()"
    >
      <feather-icon
        icon="FilterIcon"
        size="16"
        class="align-middle"
      />
      {{ $t('Components.ListHeaderFilter.Filters') }}
    </b-button>
    <list-filter-modal
      ref="refModal"
      :filters.sync="filters"
      @on-filter="onFilter"
    />
  </div>
</template>

<script>
import {
  BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ListFilterModal from './ListHeaderFilterModal.vue'

export default {
  components: {
    BButton,

    ListFilterModal,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const refModal = ref(null)
    const onFilter = () => {
      emit('on-filter')
    }

    return {
      refModal,
      onFilter,
    }
  },
}
</script>

<style>

</style>
