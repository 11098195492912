import axios from '@axios'
import common from '@/libs/common'

export default function fileService() {
  const { throwError } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  const fetchFile = (idArchivo, callback) => {
    axios
      .get(`${API_URL}/Archivos/${idArchivo}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchFileStreaming = (idArchivo, callback) => {
    axios
      .get(`${API_URL}/Archivos/${idArchivo}/Streaming`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchFileDigitalRecord = idArchivo => new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/Archivos/ExpedienteDigital/${idArchivo}`)
      .then(response => {
        resolve(response)
      })
      .catch(error => reject(error))
  })

  const fetchFileDigitalRecordStreaming = idArchivo => new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/Archivos/ExpedienteDigital/${idArchivo}/Streaming`)
      .then(response => {
        resolve(response)
      })
      .catch(error => reject(error))
  })

  const createFile = file => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Archivos`, file)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const createFiles = files => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Archivos/Multiples`, { archivos: files })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const createExcelTemplateFile = file => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Archivos/PlantillaExcel`, file)
      .then(response => {
        resolve(response.data)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  return {
    fetchFile,
    fetchFileStreaming,
    createFile,
    createFiles,
    createExcelTemplateFile,
    fetchFileDigitalRecord,
    fetchFileDigitalRecordStreaming,
  }
}
