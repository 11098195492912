<template>
  <validation-provider
    #default="validationContext"
    :name="label"
    :rules="validationRules"
  >
    <b-form-group
      :label="label"
      :label-for="`${label.replace(' ', '')}-input`"
    >
      <b-form-input
        :id="`${label.replace(' ', '')}-input`"
        v-model="inputValue"
        :state="getValidationState(validationContext)"
        trim
        :disabled="disabled"
        :type="type"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
      <b-form-invalid-feedback :state="!additionalValidation">
        {{ additionalValidationText }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { computed } from '@vue/composition-api'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    ValidationProvider,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    validationRules: {
      type: String,
      default: '',
      require: false,
    },
    label: {
      type: String,
      require: false,
      default: 'Field name',
    },
    additionalValidation: {
      type: Boolean,
      require: false,
      default: false,
    },
    additionalValidationText: {
      type: String,
      require: false,
      default: '',
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    type: {
      type: String,
      require: false,
      default: 'text',
    },
    getValidationState: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const onChange = newValue => {
      emit('input', newValue)
    }
    const inputValue = computed({
      get() {
        return props.value
      },
      set(newValue) {
        onChange(newValue)
      },
    })

    return {
      inputValue,
      onChange,
    }
  },
}
</script>

<style>

</style>
