<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <!-- List header-->
      <list-header
        :actions="actions"
        :filters.sync="filters"
        :per-page.sync="perPage"
        :search-query.sync="searchQuery"
        :search-placeholder="searchPlaceholder"
        @on-filter="refreshData"
      />
      <!-- Table -->
      <b-table
        ref="refTable"
        responsive
        :fields="tableColumnsValue"
        :items="refetchRecordsFunction"
        primary-key="id"
        :sort-by.sync="sortByValue"
        show-empty
        :empty-text="$t('Components.List.NoMatchingRecordsFound')"
        :sort-desc.sync="isSortDirDesc"
        tbody-td-class="table-succes"
        :fixed="false"
        style="overflow-y: clip;"
      >
        <template
          v-for="(_, slotName) of $scopedSlots"
          v-slot:[slotName]="scope"
        >
          <slot
            :name="slotName"
            v-bind="scope"
          />
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            :class="totalRecords == 1 ? 'm-3': ''"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-for="action in tableActions"
              :key="action.name"
              :disabled="(action.disabledCondition && action.disabledCondition(data.item)) || !$can(action.aclAction, action.aclResource)"
              @click="action.routeName ? $router.push({ name: action.routeName, params: resolveTableActionParams(action, data.item)}) : action.click(data.item)"
            >
              <feather-icon :icon="action.icon" />
              <span class="align-middle ml-50">{{ action.label }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Components.List.Showing') }} {{ dataMeta.Showing }} {{ $t('Components.List.To') }} {{ dataMeta.to }} {{ $t('Components.List.Of') }} {{ dataMeta.of }} {{ $t('Components.List.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows.sync="totalRecords"
              :per-page.sync="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="onChangePage"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <excel-template-uploader
      ref="refExcelTemplateUploader"
      :select-customer="uploadTemplateSelectCustomer"
      @on-upload="uploadTemplateFunction"
      @on-upload-callback="() => {
        refTable.refresh()
        success($t('Components.List.TemplateUploadedTitle'), $t('Components.List.TemplateUploadedText'))
      }
      "
    />
    <b-modal
      ref="refModalDelete"
      :title="$t('Components.List.DeleteConfirmationTitle')"
    >
      <b-card-text>
        {{ $t('Components.List.DeleteConfirmationMessage') }}
      </b-card-text>
      <template #modal-footer>
        <b-button
          variant="outline-danger"
          @click="deleteRecord"
        >
          <feather-icon
            icon="TrashIcon"
            size="16"
            class="align-middle text-body"
          />
          {{ $t('Components.List.DeleteConfirmationYes') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  BCardText,
  BButton,
} from 'bootstrap-vue'
import {
  ref,
  computed,
  watch,
  onMounted,
  onBeforeMount,
} from '@vue/composition-api'
import { saveAs } from 'file-saver'
import i18n from '@/libs/i18n'
import axios from '@axios'
import store from '@/store'
import common from '@/libs/common'
import ExcelTemplateUploader from '@/components/ExcelTemplateUploader.vue'
import metadataViewModels from '@/metadata/viewModels.json'
import ListHeader from './ListHeader.vue'

export default {
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BPagination,
    BDropdown,
    BDropdownItem,
    BModal,
    BCardText,
    BButton,

    ListHeader,
    ExcelTemplateUploader,
  },
  props: {
    actions: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    keyField: {
      type: String,
      required: true,
    },
    sortBy: {
      type: String,
      required: true,
    },
    sortDirDesc: {
      type: Boolean,
      required: false,
      default: true,
    },
    controllerName: {
      type: String,
      default: null,
    },
    modelName: {
      type: String,
      default: null,
    },
    refetchRecords: {
      type: Function,
      default: null,
    },
    refetchRecordsName: {
      type: String,
      required: true,
    },
    uploadTemplate: {
      type: Function,
      default: () => {},
    },
    uploadTemplateSelectCustomer: {
      type: Boolean,
      default: false,
    },
    fetchReport: {
      type: Function,
      default: null,
    },
    fetchTemplate: {
      type: Function,
      default: null,
    },
    deleteMethod: {
      type: Function,
      required: false,
      default: null,
    },
    reportName: {
      type: String,
      default: null,
    },
    templateName: {
      type: String,
      default: null,
    },
    tableColumns: {
      type: Array,
      required: true,
    },
    tableActions: {
      type: Array,
      required: true,
    },
    tableStore: {
      type: String,
      required: false,
      default: null,
    },

  },
  setup(props) {
    const { throwError } = common()
    /* Consts */
    const apiUrl = `${process.env.VUE_APP_ADUACORE_API_URL}/api`
    /* Refs */
    const refTable = ref(null)
    const refExcelTemplateUploader = ref(null)
    const refModalDelete = ref(null)

    /* List control */
    const perPage = ref(10)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortByValue = ref(props.sortBy)
    const isSortDirDesc = ref(props.sortDirDesc)

    // const perPage = ref(props.tableStore ? store.state[props.tableStore].perPage : 10)
    // const currentPage = ref(2)
    // const searchQuery = ref('')
    // const sortByValue = ref(props.tableStore ? store.state[props.tableStore].sortBy : props.sortBy)
    // const isSortDirDesc = ref(props.tableStore ? store.state[props.tableStore].isSortDirDesc : false)

    /* Data */
    const totalRecords = ref(0)
    const records = ref([])
    const recordIdForDelete = ref(0)
    const tableColumnsValue = ref(props.tableColumns)
    const searchPlaceholder = props.tableColumns.filter(f => f.key !== 'actions').map(element => element.label).join(', ')

    if (props.modelName) {
      const [metaDataViewModel] = metadataViewModels.filter(f => f.FullName === props.modelName)
      tableColumnsValue.value = metaDataViewModel.Fields.filter(f => f.ListOrder).map(m => ({
        label: m.LabelEsMx,
        key: m.CamelCaseName,
        sortable: m.ListSortable,
        sortKey: m.Name,
      }))
    }

    /* Methods, procedures & events */
    // Fetch records function
    const refetchRecordsFunction = (ctx, callback) => {
      const params = ref({
        searchQuery: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortByValue.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
      })

      props.filters.forEach(element => {
        params.value[element.name] = element.value
      })

      if (props.controllerName) {
        axios.get(`${apiUrl}/${props.controllerName}`, { params: params.value })
          .then(response => {
            records.value = response.data[props.refetchRecordsName]
            totalRecords.value = response.data.total
            callback(response.data[props.refetchRecordsName])
          })
      } else {
        props.refetchRecords(params.value, data => {
          records.value = data[props.refetchRecordsName]
          totalRecords.value = data.total
          callback(data[props.refetchRecordsName])
        })
      }
    }

    // Download report action
    const downloadReportAction = props.actions.filter(f => f.name === 'downloadReport')[0]
    if (downloadReportAction) {
      const downloadReport = () => {
        const params = ref({
          searchQuery: searchQuery.value,
          sort: `${sortByValue.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
        })

        props.filters.forEach(element => {
          params.value[element.name] = element.value
        })

        if (props.controllerName) {
          axios
            .get(`${apiUrl}/${props.controllerName}/ExportarPlantillaExcel`, { params: params.value, responseType: 'blob' })
            .then(response => {
              const file = new File([response.data], props.reportName)
              saveAs(file, props.reportName)
            })
            // .catch(error => throwError(error))
        } else {
          props.fetchReport(params.value, data => {
            const file = new File([data], props.reportName)
            saveAs(file, props.reportName)
          })
        }
      }

      downloadReportAction.label = i18n.t('Components.List.DownloadReport')
      downloadReportAction.click = downloadReport
      downloadReportAction.icon = 'DownloadIcon'
    }

    // Download template action
    const downloadTemplateAction = props.actions.filter(f => f.name === 'downloadTemplate')[0]
    if (downloadTemplateAction) {
      const downloadTemplate = () => {
        const params = ref({
          searchQuery: searchQuery.value,
          sort: `${sortByValue.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
        })

        props.filters.forEach(element => {
          params.value[element.name] = element.value
        })

        if (props.fetchTemplate != null) {
          props.fetchTemplate(params.value, data => {
            const file = new File([data], props.templateName)
            saveAs(file, props.templateName)
          })
        } else if (props.controllerName) {
          axios
            .get(`${apiUrl}/${props.controllerName}/ExportarExcelMasterItem`, { params: params.value, responseType: 'blob' })
            .then(response => {
              const file = new File([response.data], props.templateName)
              saveAs(file, props.templateName)
            })
            // .catch(error => throwError(error))
        }
      }

      downloadTemplateAction.label = downloadTemplateAction.label ? downloadTemplateAction.label : i18n.t('classification.list.downloadTemplate')
      downloadTemplateAction.click = downloadTemplate
      downloadTemplateAction.icon = 'FileTextIcon'
    }

    // Delete recods function
    const tableActionDelete = props.tableActions.filter(f => f.name === 'delete')[0]
    if (tableActionDelete) {
      tableActionDelete.click = item => {
        refModalDelete.value.show()
        recordIdForDelete.value = item[tableActionDelete.params[0]]
      }
    }

    // Upload template action
    const uploadTemplateAction = props.actions.filter(f => f.name === 'uploadTemplate')[0]

    if (uploadTemplateAction) {
      uploadTemplateAction.icon = 'UploadIcon'
      uploadTemplateAction.label = i18n.t('Components.List.UploadTemplate')
      uploadTemplateAction.click = () => refExcelTemplateUploader.value.show()
    }

    // Upload template function
    const uploadTemplateFunction = props.controllerName ? (data, callback, errorCallback) => {
      axios
        .post(`${apiUrl}/${props.controllerName}/ImportarPlantillaExcel`, data)
        .then(response => {
          callback(response.data)
        })
        .catch(error => {
          errorCallback(error.response.data)
        })
    } : props.uploadTemplate

    const dataMeta = computed(() => {
      const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalRecords.value,
      }
    })

    const refreshData = () => {
      refTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, isSortDirDesc], () => {
      if (props.tableStore) {
        store.dispatch(`${props.tableStore}/updateTable`, {
          perPage: perPage.value,
          sortBy: sortByValue.value,
          isSortDirDesc: isSortDirDesc.value,
        })
      }
      refreshData()
    })

    onMounted(() => {
      if (props.tableStore) {
        // perPage.value = store.state[props.tableStore].perPage
        // currentPage.value = 2
      }
    })

    onBeforeMount(() => {
      currentPage.value = Number(10)
    })

    // Table actions
    const deleteRecord = () => {
      if (props.controllerName) {
        axios
          .delete(`${apiUrl}/${props.controllerName}/${recordIdForDelete.value}`)
          .then(() => {
            refModalDelete.value.hide()
            refreshData()
          })
          .catch(error => throwError(error))
      } else {
        props.deleteMethod(recordIdForDelete.value, () => {
          refModalDelete.value.hide()
          refreshData()
        })
      }
    }

    const resolveTableActionParams = (tableAction, item) => {
      if (tableAction.params) {
        const params = {}
        tableAction.params.forEach(param => {
          params[param] = item[param]
        })

        return params
      }

      return {}
    }

    const onChangePage = page => {
      store.dispatch(`${props.tableStore}/updateTablePage`, page)
    }

    return {
      refTable,
      refExcelTemplateUploader,
      refModalDelete,

      perPage,
      currentPage,
      searchQuery,
      sortByValue,
      isSortDirDesc,

      totalRecords,
      records,
      deleteRecord,

      tableColumnsValue,
      searchPlaceholder,
      refetchRecordsFunction,
      refreshData,
      dataMeta,
      resolveTableActionParams,
      uploadTemplateFunction,
      onChangePage,
    }
  },
  beforeMount() {
    this.currentPage = Number(10)
  },
  methods: {
    success(title, text) {
      this.$swal({
        title,
        text,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
