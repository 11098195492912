<template>
  <validation-provider
    #default="{ errors }"
    :name="label"
    :rules="validationRules"
  >
    <b-form-group
      :label="label"
      :state="errors.length > 0 ? false:null"
      :label-for="`${label}-select`"
    >
      <v-select
        :id="`${label}-select`"
        v-model="selectedValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="texto"
        :options="options"
        :clearable="clearable"
        :reduce="reduce"
        :multiple="multiple"
        :disabled="disabled"
      />
      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
        {{ errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>

<script>
import {
  BFormGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import optionService from '@/services/option.service'
import { ValidationProvider } from 'vee-validate'

export default {
  components: {
    BFormGroup,
    BFormInvalidFeedback,

    vSelect,
    ValidationProvider,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      default: null,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    domainName: {
      type: String,
      require: true,
      default: '',
    },
    label: {
      type: String,
      require: false,
      default: 'Field name',
    },
    autoLoad: {
      type: Boolean,
      require: false,
      default: true,
    },
    validationRules: {
      type: String,
      default: '',
      require: false,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
    preloadOptions: {
      type: Array,
      require: false,
      default: null,
    },
    reduce: {
      type: Function,
      require: false,
      default: option => option.id,
    },
  },
  setup(props, { emit }) {
    /* Services */
    const {
      fetchOptions,
    } = optionService()
    /* Data */
    const options = ref([])
    const fetch = (keyLookUp, additionalKeyLookUp) => {
      if (props.preloadOptions) {
        options.value = props.preloadOptions
      } else {
        fetchOptions({ nombreDominio: props.domainName, llaveBuscar: keyLookUp || '', llaveBuscarAdicional: additionalKeyLookUp || '' }, data => {
          options.value = data
        })
      }
    }
    const onClose = newValue => {
      emit('input', newValue)
      emit('selected-option')
    }

    const selectedValue = computed({
      get() {
        return props.value
      },
      set(newValue) {
        onClose(newValue)
      },
    })

    if (props.autoLoad) {
      fetch()
    }

    return {
      selectedValue,
      fetch,
      options,
      onClose,
    }
  },
}
</script>

<style>

</style>
