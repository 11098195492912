<template>
  <b-sidebar
    id="add-new-part-sidebar"
    :visible="isAddNewPartSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-part-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('AppPart.newPart') }}
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- BODY -->
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Customer-->
          <database-select
            v-model="partData.clienteId"
            domain-name="Clientes"
            :label="$t('AppPart.columns.customer')"
            validation-rules="required"
            @selected-option="$refs.selectProveedor.fetch(partData.clienteId)"
          />
          <!-- Clave-->
          <validation-provider
            #default="validationContext"
            name="Numero de parte"
            rules="required"
          >
            <b-form-group
              :label="$t('classification.edit.columns.numberPart')"
            >
              <b-form-input
                v-model="partData.clave"
                :state="getValidationState(validationContext) && !partAlreadyExists"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
              <b-form-invalid-feedback :state="!partAlreadyExists">
                {{ $t('classification.edit.warningMessageNumber') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Descripcion -->
          <form-input
            v-model="partData.descripcion"
            :label="$t('AppPart.columns.description')"
            validation-rules="required|max:250"
            :get-validation-state="getValidationState"
          />
          <!-- Peso -->
          <form-input
            v-model="partData.peso"
            :label="$t('AppPart.columns.weight')"
            :get-validation-state="getValidationState"
            type="number"
          />
          <!-- Fraccion Arancelaria-->
          <database-select
            v-model="partData.fraccionArancelariaId"
            domain-name="FraccionesArancelarias"
            :label="$t('AppPart.columns.fraccion')"
            :clearable="false"
            validation-rules="required"
            :preload-options="$store.getters['app/fraccionesArancelarias']"
            @selected-option="$refs.selectNico.fetch(partData.fraccionArancelariaId)"
          />
          <!-- Fraccion Nicos-->
          <database-select
            ref="selectNico"
            v-model="partData.nicoId"
            domain-name="Nicos"
            :label="$t('AppPart.columns.nico')"
            :clearable="false"
            validation-rules="required"
            :auto-load="false"
          />
          <!-- Unidad de medida-->
          <database-select
            v-model="partData.unidadMedidaId"
            domain-name="UnidadesMedida"
            :label="$t('AppPart.columns.unitMeasure')"
          />
          <!-- Proveedor-->
          <database-select
            ref="selectProveedor"
            v-model="partData.proveedorId"
            domain-name="Proveedores"
            :label="$t('AppPart.columns.vendor')"
          />
          <!-- Tipo de operacion-->
          <database-select
            v-if="!userIsInRole('ClasificadorExterno')"
            v-model="partData.operacionTipoId"
            domain-name="TiposOperacion"
            :label="$t('AppPart.columns.operationType')"
            @selected-option="$refs.selectClaveDocumento.fetch(partData.operacionTipoId)"
          />
          <!--DocumentType field--->
          <database-select
            v-if="!userIsInRole('ClasificadorExterno')"
            ref="selectClaveDocumento"
            v-model="partData.documentoClaveId"
            domain-name="ClavesDocumento"
            :label="$t('AppPart.columns.documentType')"
            :auto-load="false"
          />
          <!--Material type field--->
          <database-select
            v-model="partData.parteMaterialTipoId"
            domain-name="ParteTiposMaterial"
            :label="$t('AppPart.columns.materialType')"
          />
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="addingPart"
            >
              <b-spinner
                v-if="addingPart"
                small
                class="mr-1"
              />
              {{ $t('Lists.Add') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :disabled="addingPart"
              @click="hide"
            >
              {{ $t('Lists.Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BButton,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BSpinner,
} from 'bootstrap-vue'
import DatabaseSelect from '@/components/DatabaseSelect.vue'
import FormInput from '@/components/FormInput.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required,
  alphaNum,
  email,
  min,
  max,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import partService from '@/services/part.service'
import common from '@/libs/common'

export default {
  components: {
    BSidebar,
    BForm,
    BButton,
    BFormInvalidFeedback,

    DatabaseSelect,
    FormInput,
    BFormGroup,
    BFormInput,
    BSpinner,

    // Form Validation
    ValidationObserver,
    ValidationProvider,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isAddNewPartSidebarActive',
    event: 'update:is-add-new-Part-sidebar-active',
  },
  props: {
    isAddNewPartSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      min,
      max,
    }
  },
  setup(props, { emit }) {
    const addingPart = ref(false)

    const blankPartData = {
      clienteId: null,
      clave: '',
      descripcion: '',
      descripcionAdicional: '',
      peso: null,
      fraccionArancelariaId: '',
      nicoId: null,
      unidadMedidaId: null,
      proveedorId: null,
      operacionTipoId: null,
      documentoClaveId: null,
      tipoMaterial: '',
    }

    const partData = ref(JSON.parse(JSON.stringify(blankPartData)))

    const resetpartData = () => {
      partData.value = JSON.parse(JSON.stringify(blankPartData))
    }

    const partAlreadyExists = ref(false)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetpartData)

    const {
      fetchPartExists,
      createParts,
    } = partService()

    const { userIsInRole } = common()

    store.dispatch('app/updateFracciones')

    const onSubmit = () => {
      fetchPartExists(partData.value.clienteId, partData.value.clave, response => {
        partAlreadyExists.value = response
        if (!partAlreadyExists.value) {
          addingPart.value = true
          createParts(partData.value)
            .then(() => {
              addingPart.value = false
              emit('sort-part-id-asc')
              emit('refetch-data')
              emit('update:is-add-new-part-sidebar-active', false)
            })
            .catch(() => {
              addingPart.value = false
            })
        }
      })
    }

    return {
      addingPart,
      partAlreadyExists,

      partData,

      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      userIsInRole,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-part-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
