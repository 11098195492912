<template>
  <div>
    <part-list-add-new
      :is-add-new-part-sidebar-active.sync="isAddNewPartSidebarActive"
      @refetch-data="() => { refPartsList.refreshData() }"
    />
    <list
      ref="refPartsList"
      sort-by="ParteId"
      refetch-records-name="partes"
      key-field="ParteId"
      report-name="CatalogoPartes.xlsx"
      :actions="actions"
      :filters.sync="filters"
      :table-columns="tableColumns"
      :table-actions="tableActions"
      :refetch-records="fetchParts"
      :delete-method="deletePart"
      :upload-template="createImportExcel"
      upload-template-select-customer
      :fetch-report="fetchExportExcel"
    >
      <template #cell(sincronizadoSysExpert)="data">
        <b-badge
          pill
          :variant="`light-${resolveSyncStatusVariant(data.item)}`"
          class="text-capitalize cursor-pointer"
          @click="onClickSync(data.item)"
        >
          {{ data.item.sincronizadoSysExpert && data.item.sincronizadoSlam ? $t('AppPart.synchronized'): $t('AppPart.errors') }}
          <!-- {{ data.item.previoEstado.nombre }} -->
        </b-badge>
      </template>
    </list>
    <b-modal
      ref="refModalSync"
      :title="$t('AppPart.systemsSync')"
      size="xl"
    >
      <b-row
        v-if="refSelectedPart"
      >
        <b-col
          cols="12"
          md="12"
        >
          <b-badge
            :variant="`light-${refSelectedPart.sincronizadoSysExpert ? 'success' : 'warning'}`"
          >
            SysExpert {{ refSelectedPart.sincronizadoSysExpert ? 'sincronizado' : 'no sincronizado' }} {{ refSelectedPart.sincronizacionSysExpertError }}
          </b-badge>
          <b-button
            v-if="!refSelectedPart.sincronizadoSysExpert"
            class="ml-1"
            variant="outline-primary"
            pill
            size="sm"
            @click="onClickSyncPartSysExpert(refSelectedPart)"
          >
            <feather-icon icon="RefreshCcwIcon" />
            Sincronizar
          </b-button>
        </b-col>
        <b-col
          cols="12"
          md="12"
        >
          <b-badge
            :variant="`light-${refSelectedPart.sincronizadoSlam ? 'success' : 'warning'}`"
          >
            Slam {{ refSelectedPart.sincronizadoSlam ? 'sincronizado' : 'no sincronizado' }} {{ refSelectedPart.sincronizacionSlamError }}
          </b-badge>
          <b-button
            v-if="!refSelectedPart.sincronizadoSlam"
            class="ml-1"
            variant="outline-primary"
            pill
            size="sm"
            @click="onClickSyncPartSlam(refSelectedPart)"
          >
            <feather-icon icon="RefreshCcwIcon" />
            Sincronizar
          </b-button>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div />
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BBadge,
  BRow,
  BCol,
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import { ref } from '@vue/composition-api'
import i18n from '@/libs/i18n'
import partService from '@/services/part.service'
import List from '@/components/List.vue'
import PartListAddNew from './PartListAddNew.vue'

export default {
  components: {
    BBadge,
    List,
    PartListAddNew,
    BRow,
    BCol,
    BButton,
  },
  setup() {
    const isAddNewPartSidebarActive = ref(false)
    const refPartsList = ref(null)
    const refModalSync = ref(null)
    const refSelectedPart = ref(null)
    const {
      fetchParts,
      deletePart,
      fetchExportExcel,
      createImportExcel,
      fetchSyncPartSysExpert,
      fetchSyncPartSlam,
    } = partService()

    const filters = ref([
      {
        type: 'select',
        name: 'clienteId',
        label: i18n.t('classification.list.filterByCustomer'),
        value: '',
        options: [],
        domainName: 'Clientes',
      },
    ])

    const tableColumns = [
      {
        key: 'parteId',
        label: i18n.t('AppPart.columns.partId'),
        sortable: true,
        sortKey: 'ParteId',
      },
      {
        key: 'clienteNombre',
        label: i18n.t('AppPart.columns.customer'),
        sortable: true,
        sortKey: 'ClienteNombre',
      },
      {
        key: 'clave',
        label: i18n.t('AppPart.columns.key'),
        sortable: true,
        sortKey: 'Clave',
      },
      {
        key: 'descripcion',
        label: i18n.t('AppPart.columns.description'),
        sortable: true,
        sortKey: 'Descripcion',
      },
      {
        key: 'fraccionArancelariaNombre',
        label: i18n.t('AppPart.columns.fraccion'),
        sortable: true,
        sortkey: 'FraccionArancelariaNombre',
      },
      {
        key: 'nicoNombre',
        label: i18n.t('AppPart.columns.nico'),
        sortable: true,
        sortkey: 'NicoNombre',
      },
      {
        key: 'unidadMedida.abreviacion',
        label: i18n.t('AppPart.columns.unitMeasure'),
        sortable: true,
        sortkey: 'UnidadMedida.Abreviacion',
      },
      {
        key: 'documentoClave.clave',
        label: i18n.t('AppPart.columns.documentType'),
        sortable: true,
        sortkey: 'DocumentoClave.clave',
      },
      {
        key: 'parteMaterialTipo.nombre',
        label: i18n.t('AppPart.columns.materialType'),
        sortable: true,
        sortkey: 'NicoNombre',
      },
      {
        label: i18n.t('AppPart.columns.sync'),
        key: 'sincronizadoSysExpert',
        sortkey: 'SincronizadoSysExpert',
        sortable: true,
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
        sortable: false,
      },
    ]

    const tableActions = ref([
      {
        name: 'bitacora',
        label: i18n.t('AppPart.logbook'),
        aclAction: 'read',
        aclResource: 'Partes',
        routeName: 'apps-parts-log',
        params: ['parteId'],
        icon: 'BookOpenIcon',
      },
      {
        name: 'edit',
        label: i18n.t('Lists.Edit'),
        aclAction: 'write',
        aclResource: 'Partes',
        routeName: 'apps-parts-edit',
        params: ['parteId'],
        icon: 'EditIcon',
      },
      {
        name: 'delete',
        label: i18n.t('Lists.Delete'),
        aclAction: 'delete',
        aclResource: 'Partes',
        params: ['parteId'],
        icon: 'TrashIcon',
      },
    ])

    const actions = ref([
      {
        label: i18n.t('AppPart.newPart'),
        aclAction: 'create',
        aclResource: 'Partes',
        click: () => { isAddNewPartSidebarActive.value = true },
        icon: 'PlusCircleIcon',
      },
      {
        name: 'downloadReport',
        aclAction: 'read',
        aclResource: 'Partes',
        toolTip: 'Debe tener filtro de cliente activado, para obtener datos.',
      },
      {
        name: 'uploadTemplate',
        aclAction: 'create',
        aclResource: 'Partes',
      },
    ])

    store.dispatch('app/updateFracciones')

    const resolveSyncStatusVariant = item => {
      if (item.sincronizadoSysExpert && item.sincronizadoSlam) return 'success'
      return 'warning'
    }
    const onClickSync = item => {
      refSelectedPart.value = item
      refModalSync.value.show()
    }

    const onClickSyncPartSysExpert = item => {
      fetchSyncPartSysExpert(item.parteId, data => {
        refSelectedPart.value = data
      })
    }
    const onClickSyncPartSlam = item => {
      fetchSyncPartSlam(item.parteId, data => {
        refSelectedPart.value = data
      })
    }
    return {
      refPartsList,
      refModalSync,
      isAddNewPartSidebarActive,
      refSelectedPart,

      actions,
      filters,
      tableActions,
      tableColumns,

      fetchParts,
      deletePart,
      fetchExportExcel,
      createImportExcel,
      resolveSyncStatusVariant,
      onClickSync,
      onClickSyncPartSysExpert,
      onClickSyncPartSlam,
    }
  },
}
</script>

<style lang="scss" scoped>
</style>

<style lang="scss">
</style>
