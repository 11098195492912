import axios from '@axios'
import common from '@/libs/common'

export default function partService() {
  const { throwError, throwSuccess } = common()
  const API_URL = `${process.env.VUE_APP_ADUACORE_API_URL}/api`

  /* Standard prefix names:
    Post: create, Get: fetch,
    Put: update,  Patch: partialUpdate,
    Delete: delete
  */

  // General ----------------------------------------
  const fetchParts = (data, callback) => {
    axios
      .get(`${API_URL}/Partes`, { params: data })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createParts = data => new Promise((resolve, reject) => {
    axios
      .post(`${API_URL}/Partes`, data)
      .then(response => {
        throwSuccess(response)
        resolve()
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })

  const fetchPart = ({ idPart }, callback) => {
    axios
      .get(`${API_URL}/Partes/${idPart}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }
  const updatePart = data => new Promise((resolve, reject) => {
    axios
      .put(`${API_URL}/Partes/${data.parteId}`, data)
      .then(response => {
        resolve()
        throwSuccess(response)
      })
      .catch(error => {
        reject()
        throwError(error)
      })
  })
  const deletePart = (idPart, callback) => {
    axios
      .delete(`${API_URL}/Partes/${idPart}`)
      .then(response => {
        throwSuccess(response)
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchExportExcel = (data, callback) => {
    axios
      .get(`${API_URL}/Partes/ExportarExcel`, { params: data, responseType: 'blob' })
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const createImportExcel = (data, callback, errorCallback) => {
    axios
      .post(`${API_URL}/Partes/ImportarExcel`, data)
      .then(response => {
        callback(response.data)
      })
      .catch(error => {
        errorCallback(error.response.data)
      })
  }

  const fetchPartLog = (parteId, callback) => {
    axios
      .get(`${API_URL}/Partes/${parteId}/Bitacora`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchPartExists = (clienteId, partNumber, callback) => {
    axios
      .get(`${API_URL}/Partes/${clienteId}/Existe/${encodeURIComponent(partNumber)}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchSyncPartSysExpert = (parteId, callback) => {
    axios
      .get(`${API_URL}/Partes/SincronizaParteSysExpert/${parteId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  const fetchSyncPartSlam = (parteId, callback) => {
    axios
      .get(`${API_URL}/Partes/SincronizaParteSlam/${parteId}`)
      .then(response => {
        callback(response.data)
      })
      .catch(error => throwError(error))
  }

  return {
    fetchParts,
    createParts,
    fetchPart,
    updatePart,
    deletePart,
    fetchExportExcel,
    createImportExcel,
    fetchPartLog,
    fetchPartExists,
    fetchSyncPartSysExpert,
    fetchSyncPartSlam,
  }
}
