<template>
  <b-modal
    ref="refModal"
    no-close-on-backdrop
    hide-footer
    modal-class="modal-primary"
    centered
    :title="$t('Components.ListHeaderFilterModal.Title')"
  >
    <b-form>
      <b-form-group
        v-for="filter in filters"
        :key="filter.name"
        :label="filter.label"
      >
        <component
          :is="resolveComponent(filter.type)"
          v-model="filter.value"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="texto"
          :options="filter.options"
          :reduce="option => option.id"
          :placeholder="filter.label"
          :disabled="filter.disabled && filter.disabled === true"
          reset-button
          locale="es"
        />
      </b-form-group>
    </b-form>
    <b-button
      variant="primary"
      block
      @click="onFilter"
    >
      <feather-icon
        icon="FilterIcon"
        size="20"
        class="align-middle"
      />
      {{ $t('Components.ListHeaderFilterModal.Filter') }}
    </b-button>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BButton,
  BFormDatepicker,
  BFormCheckbox,

} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import optionService from '@/services/option.service'

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,

    vSelect,
  },
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const refModal = ref(null)

    const {
      fetchOptions,
      fetchTextOptions,
    } = optionService()

    const show = () => {
      refModal.value.show()
    }

    const onFilter = () => {
      refModal.value.hide()
      emit('on-filter')
    }
    const resolveComponent = type => {
      if (type === 'date') return 'b-form-datepicker'
      if (type === 'select') return 'v-select'
      if (type === 'checkbox') return 'b-form-checkbox'

      return ''
    }

    const { filters } = props
    filters.forEach(filter => {
      if (filter.type === 'select' && filter.domainName && filter.domainTextOptions !== true) {
        fetchOptions({ nombreDominio: filter.domainName }, data => {
          // eslint-disable-next-line no-param-reassign
          filter.options = data
        })
      }
      if (filter.type === 'select' && filter.domainName && filter.domainTextOptions === true) {
        fetchTextOptions({ nombreDominio: filter.domainName }, data => {
          // eslint-disable-next-line no-param-reassign
          filter.options = data
        })
      }
    })

    return {
      refModal,

      show,
      onFilter,
      resolveComponent,
    }
  },
}
</script>

<style>

</style>
