<template>
  <div class="m-2">
    <!-- Table Top -->
    <b-row>
      <!-- Per Page -->
      <b-col
        cols="12"
        md="2"
        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
      >
        <label>{{ $t('Components.ListHeader.Show') }}</label>
        <v-select
          v-model="perPageValue"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="perPageOptions"
          :clearable="false"
          class="per-page-selector d-inline-block mx-50"
        />
        <label>{{ $t('Components.ListHeader.Entries') }}</label>
      </b-col>
      <!-- Search -->
      <b-col
        cols="12"
        md="4"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-form-input
            v-model="searchQueryValue"
            class="d-inline-block mr-1"
            :placeholder="$t('Components.ListHeader.Search') + searchPlaceholder"
          />
        </div>
      </b-col>
      <!-- Filters -->
      <b-col
        cols="12"
        md="3"
      >
        <list-filter
          v-if="filters.length > 0"
          :filters.sync="filters"
          @on-filter="onFilter"
        />
      </b-col>
      <!-- Actions -->
      <b-col
        cols="12"
        md="3"
      >
        <b-dropdown
          v-if="actions.length > 0"
          variant="outline-primary"
          :right="$store.state.appConfig.isRTL"
          block
          menu-class="w-100"
        >
          <template #button-content>
            <feather-icon
              icon="CommandIcon"
              size="16"
              class="align-middle"
            />
            {{ $t('Components.ListHeader.Actions') }}
          </template>
          <b-dropdown-item
            v-for="action in actions"
            :key="action.label"
            v-b-tooltip.hover.v-info
            :title="action.toolTip ? action.toolTip: ''"
            :disabled="!$can(action.aclAction, action.aclResource)"
            @click="action.routeName ? $router.push({ name: action.routeName, params: action.routeParams}) : action.click()"
          >
            <feather-icon :icon="action.icon" />
            <span class="align-middle ml-50" v-if="action.name === 'dateAndHour'">{{ action.value ? $t('Components.List.Filters.DateWhitoutHour') : $t('Components.List.Filters.FormatDate') }}</span>
            <span class="align-middle ml-50" v-else>{{ action.label }}</span>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import ListFilter from './ListHeaderFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BDropdown,
    BDropdownItem,
    BFormInput,
    vSelect,
    ListFilter,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    actions: {
      type: Array,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    searchQuery: {
      type: String,
      required: true,
    },
    searchPlaceholder: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const perPageValue = ref(props.perPage)
    const perPageOptions = [10, 25, 50, 100]
    const searchQueryValue = ref(props.searchQuery)

    const onFilter = () => {
      emit('on-filter')
    }

    watch([perPageValue], () => {
      emit('update:per-page', perPageValue.value)
    })

    watch([searchQueryValue], () => {
      emit('update:search-query', searchQueryValue.value)
    })

    return {
      perPageValue,
      perPageOptions,
      searchQueryValue,
      onFilter,
    }
  },
}
</script>

<style>

</style>
