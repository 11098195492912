<template>
  <b-modal
    ref="refModal"
    no-close-on-backdrop
    hide-footer
    modal-class="modal-primary"
    centered
    :title="$t('Components.ExcelTemplateUploader.Title')"
  >
    <div
      v-if="!uploading && uploadErrors.length === 0"
      id="upload-template"
    >
      <b-alert
        v-if="templateFile.mensajeLecturaExcel"
        v-height-fade
        show
        fade
        class="p-1"
        :variant="templateFile.mensajeLecturaExcel === 'OK' ? 'success' : 'danger'"
      >
        {{ templateFile.mensajeLecturaExcel === 'OK' ? $t('Components.ExcelTemplateUploader.ExcelFileOk') : $t('Components.ExcelTemplateUploader.ExcelFileError') }}
      </b-alert>
      <b-form-group :label="$t('Components.ExcelTemplateUploader.File')">
        <file-uploader
          v-model="templateFile"
          file-type="excelTemplate"
          @on-delete-file="onDeleteFile"
        />
      </b-form-group>
      <b-form-group
        v-if="templateFile.hojas"
        :label="$t('Components.ExcelTemplateUploader.SelectBookSheet')"
      >
        <v-select
          v-model="selectedSheet"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="templateFile.hojas"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        v-if="selectCustomer && templateFile.hojas"
        :label="$t('Components.ExcelTemplateUploader.Customer')"
      >
        <v-select
          v-model="selectedCustomer"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="customers"
          :reduce="r => r.id"
          label="texto"
          :clearable="false"
        />
      </b-form-group>
      <b-button
        v-if="selectedSheet !== '' && (!selectCustomer || selectCustomer && selectedCustomer > 0)"
        variant="primary"
        block
        @click="loadTemplate"
      >
        <feather-icon
          icon="UploadIcon"
          size="20"
          class="align-middle"
        />
        <strong> {{ $t('Components.ExcelTemplateUploader.LoadExcelTemplate') }}</strong>
      </b-button>
    </div>
    <div
      v-if="uploading"
      id="uploading-template"
    >
      <b-spinner
        block
      />
      <strong> {{ $t('Components.ExcelTemplateUploader.LoadingTemplate') }}</strong>
    </div>
    <div
      v-if="uploadErrors.length > 0"
      id="upload-errors"
    >
      <b-alert
        v-height-fade
        show
        fade
        class="p-1"
        variant="danger"
      >
        <p>{{ $t('Components.ExcelTemplateUploader.UploadErrors') }}: </p>
        <div
          v-if="Array.isArray(uploadErrors)"
        >
          <span
            v-for="error in uploadErrors"
            :key="error"
          >
            <li>{{ error }}</li>
          </span>
        </div>
        <span v-if="!Array.isArray(uploadErrors)">{{ uploadErrors }}</span>
      </b-alert>
      <b-button
        variant="primary"
        block
        @click="onUploadAgain()"
      >
        {{ $t('Components.ExcelTemplateUploader.UploadAgain') }}
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BButton,
  BAlert,
  BSpinner,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import vSelect from 'vue-select'
import { heightFade } from 'vue-ripple-directive'
import optionService from '@/services/option.service'
import FileUploader from './FileUploader.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BButton,
    BAlert,
    BSpinner,
    FileUploader,
    vSelect,
  },
  directives: {
    heightFade,
  },
  props: {
    selectCustomer: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const refModal = ref(null)
    const templateFile = ref('')
    const selectedSheet = ref('')
    const selectedCustomer = ref(null)
    const uploading = ref(false)
    const uploadErrors = ref([])

    const {
      fetchOptions,
    } = optionService()

    const customers = ref([])
    fetchOptions({ nombreDominio: 'Clientes' }, data => {
      customers.value = data
    })

    const show = () => {
      refModal.value.show()
    }

    const loadTemplate = () => {
      uploading.value = true
      emit('on-upload',
        // Request object
        { guid: templateFile.value.guid, clienteId: selectedCustomer.value, hojaExcel: selectedSheet.value },
        // Success response
        () => {
          uploading.value = false
          refModal.value.hide()
          emit('on-upload-callback')
        },
        // Template validation errors
        errors => {
          uploading.value = false
          uploadErrors.value = errors
        })
    }

    const onDeleteFile = () => {
      selectedSheet.value = ''
      selectedCustomer.value = null
    }
    const onUploadAgain = () => {
      uploadErrors.value = []
      templateFile.value = ''
    }

    return {
      refModal,
      templateFile,
      selectedSheet,
      selectedCustomer,
      uploading,
      uploadErrors,
      customers,

      show,
      loadTemplate,
      onDeleteFile,
      onUploadAgain,
    }
  },
}
</script>

<style>

</style>
