<template>
  <div>
    <b-input-group
      v-if="value === '' && !importingFile"
    >
      <b-form-file
        v-model="file"
        :browse-text="$t('fileUploader.browseText')"
        :placeholder="$t('fileUploader.noFileSelected')"
        :state="state"
        @input="onUploadFile"
      />
    </b-input-group>
    <div
      v-if="value !== '' && file"
    >
      {{ file.name }}
      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XCircleIcon"
        size="16"
        @click="onDeleteFile"
      />
    </div>
    <div
      v-if="importingFile"
    >
      <b-spinner />
      Cargando archivo...
    </div>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormFile,
  BSpinner,
} from 'bootstrap-vue'
import fileService from '@/services/file.service'
import { ref } from '@vue/composition-api'
import common from '@/libs/common'

export default {
  components: {
    BInputGroup,
    BFormFile,
    BSpinner,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true,
    },
    fileName: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-prop-types
    state: {
      default: null,
    },
    fileType: {
      type: String,
      default: 'standard',
    },
  },
  setup(props, { emit }) {
    /* Services */
    const { createFile, createExcelTemplateFile } = fileService()
    /* Data */
    const file = ref(null)
    const importingFile = ref(false)
    /* Methods */

    const uploadFile = byteArray => {
      if (props.fileType === 'standard') {
        createFile({ binarios: byteArray, nombreArchivo: file.value.name })
          .then(data => {
            importingFile.value = false
            emit('input', data)
            emit('update:file-name', file.value.name)

            emit('on-upload-file')
          })
      }
      if (props.fileType === 'excelTemplate') {
        createExcelTemplateFile({ binarios: byteArray, nombreArchivo: file.value.name })
          .then(data => {
            importingFile.value = false
            emit('input', data)
            emit('update:file-name', file.value.name)
            emit('on-upload-file')
          })
      }
    }

    const onUploadFile = () => {
      importingFile.value = true
      const reader = new FileReader()
      reader.readAsArrayBuffer(file.value)
      reader.onload = () => {
        const byteArray = []

        if (file.value.name.endsWith('jpeg') || file.value.name.endsWith('jpg') || file.value.name.endsWith('png') || file.value.name.endsWith('PNG')) {
          // *
          const blob = new Blob([reader.result]) // create blob...
          window.URL = window.URL || window.webkitURL
          const blobURL = window.URL.createObjectURL(blob) // and get it's URL
          // helper Image object
          const image = new Image()
          image.src = blobURL
          image.onload = () => {
            // have to wait till it's loaded
            common().resizeImage(image).toBlob(blobResize => {
              // Convert blobl to array buffer
              blobResize.arrayBuffer()
                .then(buff => {
                  const bytesRaw = new Uint8Array(buff)
                  // Convert arrayBuffer to simple array bytes
                  // eslint-disable-next-line no-restricted-syntax
                  for (const element of bytesRaw) {
                    byteArray.push(element)
                  }

                  uploadFile(byteArray)
                })
            })
          }
        } else {
          // Get array buffer, convert and post to server
          const arrayBuffer = reader.result
          const bytes = new Uint8Array(arrayBuffer)
          // eslint-disable-next-line no-restricted-syntax
          for (const element of bytes) {
            byteArray.push(element)
          }

          uploadFile(byteArray)
        }
      }
    }
    const onDeleteFile = () => {
      file.value = null
      emit('input', '')
      emit('file-name', '')
      emit('on-delete-file')
    }

    return {
      // Data
      file,
      importingFile,
      // Methods
      onUploadFile,
      onDeleteFile,
    }
  },
}
</script>

<style>

</style>
